.adminTendersDiv .container-title {
    text-align: center;
  }
  
  .homepageSlider {
    margin: 0 0 50px;
  }
  
  /* .adminTendersDiv{
      margin: 20px 0;
  } */
  .card-body button {
    color: white !important;
    background-color: #3ecf8e !important;
    padding: 5px;
    border: 1px solid #3ecf8e;
  }
  
  .card-body button span {
    color: white !important;
  }
  .explore-product-wrapper{
    margin-bottom: 7px;
  }
  /* .explore-product-wrapper{
      margin: 0px 0 0px;
  } */
  /* #tendersContainer
  {
      margin: 30px 0 ;
  } */
  
  .explore-product-wrapper h3 {
    padding-top: 70px;
  }
  .explore-product-wrapper .card-body span {
    color: #808080;
  }
  #tendersContainer .container-title {
    text-align: center;
    display: block;
  }
  
  .card-body img {
    transition: transform 0.2s;
  }

  /* .card-body img:hover {
  transform: scale(1.5);
  width: 80% !important;
  height: 150px !important;
  margin: 30px auto;
  } */
  .explore-cards {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 8px;
    background-color:#fff;
  }
  .explore-cards .signbutton {
    background-color: #3ecf8e;
    border-color: #3ecf8e;
    margin: 0 auto;
  }
  .search-bar-container{
    margin-bottom: 40px;
  }
  .explore-button-container {
    margin-top: 10px;
    border-top: 1px solid #3ecf8e;
    padding: 10px 0;
  }
  .explore-cards-image-container {
    height: 200px;
  }
  .explore-cards-image-container img {
    width: 100%;
    height: 200px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  .explore-cards-image-container img:hover{
    cursor: initial !important;
  }
  .explore-prooduct-card-body {
    height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 10px 10px;
  }
  .explore-prooduct-card-body p {
    font-size: 12px;
    margin-bottom: 8px;
    color: #808080;
  }
  .explore-prooduct-card-body h2{
    margin-top: 0;
  }
  .product-original-price {
    text-decoration: line-through;
  }
  .product-video{
    width: 100% !important;
    height: 180px !important;
  }
  
  .product-name {
    color: #3ecf8e;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 80%;
  }
  .products-like {
    color: #3ecf8e !important;
    font-size: 12px !important;
  }
  .products-like small {
    color: #3ecf8e !important;
    font-size: 16px !important;
    line-height: 13px;
  }
  .products-like svg {
    cursor: pointer;
    width: 20px !important;
    height: 20px;
  }
  .explore-prooduct-card-body-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .explore-button-container button{
    display: block;
    margin: 0px auto !important;
    color: white;
    border: none;
    padding: 6px 10px;
    border-radius: 6px;
  }
  /* .explore-button-container button:hover{
    transition: none;
  } */

  .explore-product-main-cont{
    justify-content: center;
  }
  @media screen and (min-width: 769px) and (max-width: 993px) {
    .explore-product-wrapper h3 {
      padding-top: 50px;
    }
    .navbarRight--mod {
      width: auto !important;
    }
    .navbarRight--mod .homepageBtn {
      width: auto !important;
    }
    .ForwardRef-root-2 {
      width: 100% !important;
    }
  }
  .explore-prooduct-card-body h2 {
    font-size: 20px;
    line-height: 28px;
    color: #575757;
  }
  .image-placeholder {
    height: 100%;
    width: 100%;
    background-color: #e6e6e6;
  }
  .ForwardRef-root-2 {
    width: 200px;
    height: 38px;
    box-shadow: none !important;
    border: 1px solid #ced4da !important;
    height: 38px !important;
    color: #808080;
  }
  .ForwardRef-root-2 .MuiInputBase-input,
  .ForwardRef-root-2::placeholder {
    color: #808080 !important;
    font-weight: 400;
  }
  .ForwardRef-iconButton-3 .MuiIconButton-label svg path {
    fill: #212529 !important;
  }
  .ForwardRef-iconButton-3 .MuiSvgIcon-root {
    height: 1.1rem !important;
    width: 1.1rem !important;
  }
  
  @media screen and (max-width: 769px) {
    .explore-product-wrapper .homepageSlider {
      margin: 0 0 0;
    }
    .explore-product-wrapper {
      margin-bottom: 35px;
  }
    .explore-product-wrapper h3{
      padding-top: 50px;
    }
  }
  
  
  .explore-products-ncards{
    border: 1px solid rgb(231 229 229);
    padding: 20px 10px;
  }
  
  .explore-products-ncards h2{ 
      color: #3ecf8e;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
  }
  
  .explore-products-ncards h4{
    font-size: 18px;
      font-weight: bold;
      color: #000;
  
  }
  .explore-products-ncards p{
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 4px;
  }
  
  .explore-products-ncards p span{
    color: #323131bf;
    font-weight:400;
    margin-left: 2px;
  }
  .scrollable-div{
    max-height: 340px;
    overflow-y: auto;
  }
  
  .explore-products-ncards .img-qr{
    height: 100px !important;
    width: 100px !important;
  }
  
  .explore-products-ncards .products-like small{
    color: #212529 !important;
  }
  
  .explore-products-ncards .explore-button-container{
    border-top: none !important;
  }
  
  
  .explore-products-ncards img{
  border-radius: 0 !important;
  }
  
  
  .explore-prooduct-card-body::-webkit-scrollbar,
  .scrollable-div::-webkit-scrollbar {
    width: 10px;
  }
  
  .explore-prooduct-card-body::-webkit-scrollbar-track,
  .scrollable-div::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  .explore-prooduct-card-body::-webkit-scrollbar-thumb,
  .scrollable-div::-webkit-scrollbar-thumb {
    background-color: #e0e0e0;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
  
  .explore-products-ncards .btn-success{
    background-color: #3ecf8e !important;
    border-color: #3ecf8e;
  }
  .likeCount{
    cursor: pointer;
    margin-left: 3px;
  }
  
  @media screen and (max-width:1050px) and (min-width:993px){
    .explore-products-ncards h4 {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width:993px){
    .explore-products-ncards{
      padding: 0px;
    }
    .scrollable-div{
      height: 196px;
      padding: 0 20px 20px;
    }
    .explore-products-ncards .explore-button-container{
    
    border-top: 1px solid #3ecf8e !important;
    }
  }