.modal--mod {
  /* width: 70%; */
  position: fixed;
  display: flex;
  top: 45%;
  transform: translate(-40%, -40%);
  min-width: 500px;
  /* min-height: 600px; */
  max-height: 70vh;
  z-index: 1300;
  box-shadow: 1px 1px 7px 2px #888888;
  background-color: #fff;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  left: 50%;
  align-content: center;
  margin: 10px;
  padding: 10px;
}

.modal--mod.closed {
  display: none;
}

.modal-guts {
  margin-top: 12%;
  background-color: white;
  border-radius: 0.25rem;
  width: 100%;
  padding: 0.1rem;
  position: absolute;
}

.modal-overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.modal-overlay.closed {
  display: none;
}

.CustomPopup-header {
  text-align: center;
  width: 100%;
  background: #fff;
}

.CustomPopup-header__title {
  font-weight: 700;
  font-size: 1.4rem;
}
/* .CustomPopup-header h2{
  float: left;
  width: 100%;
  display: block;
} */
.closeButton {
  color: red;
  /* font-size: 18px;
  float: right;
  margin-right: 50px; */
  position: absolute;
  top: -10px;
  right: 10px;
  font-size: 27px;
}
.closeButton:hover {
  cursor: pointer !important;
}

@media screen and (max-width: 639px) {
  .modal--mod {
    position: fixed;
    display: flex;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 10px);
    height: 90vh;
    z-index: 1300;
    box-shadow: 1px 1px 7px 2px #888888;
    background-color: #fff;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    left: 50%;
  }
  .CustomPopup-header {
    position: absolute;
    text-align: center;
    width: 100%;
    background: #fff;
  }
  .modal-guts {
    margin-top: 22%;
    background-color: white;
    border-radius: 0.25rem;
    width: 100%;
    position: absolute;
  }
}

@media screen and (min-width: 640px) {
  .modal-guts {
    margin-top: 12%;
    background-color: white;
    border-radius: 0.25rem;
    width: 100%;
    position: absolute;
  }
}
