/* .leftHeadings{
    text-align: left;
    padding: 20px 0 0;
}
.leftHeadings li{
    list-style: none;
    padding: 10px 0;
}

 .custom-container--mod{
    padding: 100px 0 20px;
} */


.terms-container{
    padding: 120px 0 60px;
}
.terms-container .container-title{
    font-size: 36px;
    margin-bottom: 35px;
}
.terms-container p{
    color: #1a1a1a;
    font-size: 16px;
    line-height: 24px;
    margin:  0 0 10px;
}

.terms-container h3{
    color: #000;
    font-size: 22px;
    line-height: 30px;
    margin:  20px 0 20px;
}
.terms-container .MuiGrid-justify-content-xs-center{
    display: flex;
}
.terms-container  ol{
    padding-left: 20px;
}

.terms-container ol li{
     color: #1a1a1a;
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 6px;
}

@media screen and (max-width:769px){
    .terms-container{
        padding: 100px 0 40px;
    }
    .terms-container .container-title{
        font-size: 30px;
        margin-bottom: 20px;
    }
    .terms-container h3{
        font-size: 20px;
        margin:  18px 0 18px;
    }
}
