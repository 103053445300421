.adminTendersDiv .container-title {
  text-align: center;
}

.homepageSlider {
  margin: 0 0 50px;
}

/* .adminTendersDiv{
    margin: 20px 0;
} */
.card-body button {
  color: white !important;
  background-color: #3ecf8e !important;
  padding: 5px;
  border: 1px solid #3ecf8e;
}

.card-body button span {
  color: white !important;
}
.explore-product-wrapper {
  margin-bottom: 7px;
}
/* .explore-product-wrapper{
    margin: 0px 0 0px;
} */
/* #tendersContainer
{
    margin: 30px 0 ;
} */

.explore-product-wrapper h3 {
  padding-top: 70px;
}
.explore-product-wrapper .card-body span {
  color: #808080;
}
#tendersContainer .container-title {
  text-align: center;
  display: block;
}

.card-body img {
  transition: transform 0.2s;
}

.signbutton:hover {
  border: 1px solid #3ecf8e;
}
/* .card-body img:hover {
transform: scale(1.5);
width: 80% !important;
height: 150px !important;
margin: 30px auto;
} */
.explore-cards {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 8px;
  background-color: #fff;
}
.explore-cards .signbutton {
  background-color: #3ecf8e;
  border-color: #3ecf8e;
  margin: 0 auto;
}
.explore-button-container {
  border-top: 1px solid #3ecf8e;
}
.explore-cards-image-container {
  height: 200px;
}
.explore-cards-image-container img {
  width: 100%;
  height: 200px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.explore-cards-image-container img:hover {
  cursor: initial !important;
}
.explore-prooduct-card-body {
  height: 190px;
  overflow-y: scroll;
  padding: 0 20px;
}
.explore-prooduct-card-body p {
  font-size: 12px;
  margin-bottom: 8px;
  color: #808080;
}
.product-original-price {
  text-decoration: line-through;
}

.product-name {
  color: #3ecf8e;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  width: 80%;
}
.products-like {
  color: #3ecf8e !important;
  font-size: 12px !important;
}
.products-like small {
  color: #3ecf8e !important;
  font-size: 16px !important;
  line-height: 13px;
}
.products-like svg {
  cursor: pointer;
  width: 20px !important;
  height: 20px;
}
@media screen and (min-width: 769px) and (max-width: 993px) {
  .explore-product-wrapper h3 {
    padding-top: 50px;
  }
  .navbarRight--mod {
    width: auto !important;
  }
  .navbarRight--mod .homepageBtn {
    width: auto !important;
  }
  .ForwardRef-root-2 {
    width: 100% !important;
  }
}
.explore-prooduct-card-body h2 {
  font-size: 20px;
  line-height: 28px;
  color: #575757;
}
.image-placeholder {
  height: 100%;
  width: 100%;
  background-color: #e6e6e6;
}
.ForwardRef-root-2 {
  width: 200px;
  height: 38px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  height: 38px !important;
  color: #808080;
}
.ForwardRef-root-2 .MuiInputBase-input,
.ForwardRef-root-2::placeholder {
  color: #808080 !important;
  font-weight: 400;
}
.ForwardRef-iconButton-3 .MuiIconButton-label svg path {
  fill: #212529 !important;
}
.ForwardRef-iconButton-3 .MuiSvgIcon-root {
  height: 1.1rem !important;
  width: 1.1rem !important;
}

@media screen and (max-width: 769px) {
  .explore-product-wrapper .homepageSlider {
    margin: 0 0 0;
  }
  .explore-product-wrapper {
    margin-bottom: 35px;
  }
  .explore-product-wrapper h3 {
    padding-top: 50px;
  }
  .explore-cards-image-container img{
    margin: 0 auto;
    width: auto;
    display: block;
  }
}

.explore-products-ncards {
  border: 1px solid rgb(231 229 229);
  padding: 20px 10px;
}

.explore-products-ncards h2 {
  color: #3ecf8e;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
}

.explore-products-ncards h4 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.explore-products-ncards p {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-bottom: 4px;
}

.explore-products-ncards p span {
  color: #323131bf;
  font-weight: 400;
  margin-left: 2px;
}
.scrollable-div {
  max-height: 340px;
  overflow-y: auto;
}

.explore-products-ncards .img-qr {
  height: 140px !important;
  width: 140px !important;
}

.explore-products-ncards .products-like small {
  color: #212529 !important;
}

.explore-products-ncards .explore-button-container {
  border-top: none !important;
}

.explore-products-ncards img {
  border-radius: 0 !important;
}

.explore-prooduct-card-body::-webkit-scrollbar,
.scrollable-div::-webkit-scrollbar {
  width: 10px;
}

.explore-prooduct-card-body::-webkit-scrollbar-track,
.scrollable-div::-webkit-scrollbar-track {
  background: #ffffff;
}

.explore-prooduct-card-body::-webkit-scrollbar-thumb,
.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.explore-products-ncards .btn-success {
  background-color: #3ecf8e !important;
  border-color: #3ecf8e;
}
.likeCount {
  cursor: pointer;
  margin-left: 3px;
}

@media screen and (max-width: 1050px) and (min-width: 993px) {
  .explore-products-ncards h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 993px) {
  .explore-products-ncards {
    padding: 0px;
  }
  .scrollable-div {
    height: 196px;
    padding: 0 20px 20px;
  }
  .explore-products-ncards .explore-button-container {
    border-top: 1px solid #3ecf8e !important;
  }
}
.offcanvas-body {
  flex-grow: inherit !important;
}

.offcanvas-body h3 {
  font-size: 18px;
  font-weight: 500;
  color: #121212bf;
}
.offcanvas-header {
  display: block;
  border-bottom: 0.8px solid rgb(231 229 229);
}
.offcanvas-header h5 {
  font-size: 18px;
  color: #000;
}

.offcanvas-header p {
  font-size: 14.5px;
  color: #121212b3;
  font-weight: 400;
}

.offcanvas-header .btn-close {
  position: absolute;
  right: 20px;
  top: 18px;
  height: 20px;
  width: 20px;
}

.offcanvas-footer {
  border-top: 0.8px solid rgb(231 229 229);
  padding: 20px;
}

.offcanvas-footer button {
  width: 150px;
}

.offcanvas-footer button {
  color: #fff;
  background-color: #3ecf8e;
  border: none;
  padding: 10px 10px;
  border-radius: 6px;
}
.offcanvas-footer .btn-mod {
  background: white !important;
  border: 1px solid #3ecf8e !important;
  color: #3ecf8e !important;
}
.offcanvas-body-content input[type="checkbox"] {
  position: relative;
  border: 1px solid #3ecf8e;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}
.offcanvas-body-content input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.offcanvas-body-content input[type="checkbox"]:checked {
  background-color: #3ecf8e;
  opacity: 1;
}

.highestPrice {
  font-size: 17px;
  color: #808080;
  font-weight: 400;
}

.price-range-container input,
.price-range-container select{
  width: 80%;
  border-radius: 4px;
  border: 1px solid #808080;
  padding: 6px 8px;
  font-size: 16px;
}
.price-range-container select{
  width: 100% !important;
}
.pricerange-container {
  border: .8px solid #c9c8c8;
  border-radius: 50px;
  color: #121212bf;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 10px;
}
.pricerange-container .MuiSvgIcon-root{
  height: 16px !important;
  width: 16px !important;
}

.subcategoryButton{
  border: 0.8px solid #c9c8c8 !important;
    /* border-radius: 50px !important; */
    color: #121212bf !important;
    /* font-size: 14px !important;
    font-weight: 400 !important; */
    padding: 6px 10px !important;
}
.toggleButton{
  font-weight: bold;
  color: #3ecf8e;
}
.filter-sec{
  cursor: pointer;
}

.explore-products-ncards h4{
  word-break: break-all;
}

.noShadow{
  box-shadow: none !important;
}

@media screen and (max-width:360px) {
  .filter-sec{
    font-size: 14px;
  }

  .filter-sec svg{
    font-size: 22px;
  }
}