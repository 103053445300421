.challenge-container img{
  width:100% !important;
  height: 100% !important;
}

.challenge-container img:hover{
    cursor: initial !important;
}

.challangeCards{
  /* border:0.8px solid #3ecf8e; */
  padding: 8px;
}
.challangeCards h6{
  font-size: 20px;
  line-height: 29px;
  font-weight: 600;
  color: #000;
}

.challangeCards p{
  font-size: 16px;
  line-height: 26px;
  color: #707070;
}
.challangeCards img{
  width: 45px !important;
  height: auto !important;
}


@media screen and (max-width:768px){
.challangeCards {
  box-shadow: 0px 3px 12px #0000000d;
  padding: 24px;
  background-color: #fff;
}

.challangesRow{
  overflow-x: auto;
  flex-wrap: nowrap;
}
.challangesRow::-webkit-scrollbar {
  display: none;
}

}